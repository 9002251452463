.App {
    text-align: center;
}


.overlay {
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.overlay:hover {
    opacity: 0.15;
}
